import React, { useState } from "react";
import { render } from "react-dom";
import Site from "./Components/Site/site";

import "./style.css";

const App = () => {
  const [name] = useState("rentabee.ch");
  document.title = name;

  return (
      <Site />
    );

};

render(<App />, document.getElementById("root"));
export default App;
