import React from "react";
import { Page } from "../Page/Page";
import FormCustomer from "./formcustomer";

export default () => { 
  const inputTop = `
  ### Anmelden für die Bienensaison 2023!
  - - -
  `;
  const comps = [
    <FormCustomer />,
  ];
  return (

    <Page 
    inputTop = {inputTop}
    comps={comps} />

  );
};
