import React from "react";
import { Page } from "../../Components/Page/Page";

export default () => {
  /*const url = null;*/
  const inputTop = `
### Wenn Du bei rentabee.ch mitmachst, hast Du Anrecht auf die folgenden Leistungen:
---
`;
  const inputBot = `
* 1 rentabee.ch-Zertifikat, das Dich als Besitzer eines Bienenvolkes während eines Kalenderjahres ausweist. Darauf aufgeführt sind auch die Kontaktdaten Deines Verband-Imkers. Du kannst Dich dann direkt bei ihm melden. Er freut sich auf Dich!
* 1 Bienenvolk bei Deinem rentabee.ch-Imker wird am Kasten mit Deinem Namen angeschrieben.
* Mindestens 1 Besuch beim Imker/bei der Imkerin während der Bienensaison (April bis August). Du darfst bei der Arbeit zuschauen und soweit es geht auch selber mithelfen.
* Eigener Honig: Grundsätzlich hast Du Anrecht auf 3 Kilo Honig, abgefüllt in 6 x 500 Gramm Gläser, von Deinem Bienenvolk. In einem normalen Honigjahr ist das problemlos möglich. Du trägst aber auch ein gewisses Risiko: Es kommt vor, dass ein Bienenvolk krank wird und stirbt oder dass ein Bienenvolk schwärmt. Dann schenkt Dir der Imker/die Imkerin trotzdem ein Kilo Honig und Du darfst bei der Pflege der anderen Völker, wie oben beschrieben, dabei sein. Dein rentabee.ch-Beitrag hilft in diesem Fall dem Imker, ein neues Bienenvolk zu züchten oder zu kaufen.
* Im Preis für rentabee.ch sind weiter inbegriffen:  
    * 6 x 500 Gramm Glas
    * Honigetiketten mit Deinem Namen  
  
Kleingedrucktes bei rentabee.ch ist nicht klein gedruckt. Du findest alle Informationen unter diesem Link:  
[Zum Kleingedruckten](/pdf/AGBs_2022.pdf)
`;

  return (

      <Page inputTop={inputTop} inputBot={inputBot} />

  );
};
