import React from "react";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";



export function Page(props: any) {
  const { inputTop, inputBot, inputFooter } = props;
  const { comps } = props;
  const { headerImage } = props;
  const { newUrl } = props;


  return (
    <React.Fragment>
      {inputTop ? <ReactMarkdown source={props.inputTop} /> : null}
      {headerImage ? <img src={headerImage} alt="Bienen im Winter" /> : null}
      {inputBot ? <ReactMarkdown source={props.inputBot} /> : null}
      {inputFooter ? <ReactMarkdown className="footerKontakt" source={props.inputFooter} /> : null}
      {newUrl ? <Link to={newUrl}>Zum Anmeldeformular</Link> : null}

      {comps
        ? comps.map((c: any, index: number) => (
            <React.Fragment key={index}>{c}</React.Fragment>
          ))
        : null}
    </React.Fragment>
  );
}
export default Page;
