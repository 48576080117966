import React from "react";
import { Page } from "../Page/Page";

export default () => {
  const inputTop = `
  ### So erreichst Du uns:
  ---
  `;
  const inputBot = `
  rentabee.ch: Bienenpatenschaften für die Schweiz.  
  Waaghausgasse 18, Postfach, CH-3001 Bern.  
  info@rentabee.ch; www.rentabee.ch

  Weitere Infos zu rentabee.ch:  
  rentabee.ch ist eine nicht-gewinnorientierte, private Initiative, die von mittlerweile rund 100 Imkerinnen und Imker in der ganzen Schweiz, vom Imkerinnen- und Imkerverein Bern und Umgebung, sowie vom Verband Bernischer Bienenzüchtervereine VBBV unterstützt wird. Gründer und Verantwortlicher ist Thomas Eberhard. Er ist selber leidenschaftlicher Imker. Seine Bienen fliegen mitten in der Stadt Bern. rentabee.ch existiert seit 2008. Rechtlich ist rentabee.ch eine Interessengemeinschaft (IG).


  `;
  const inputFooter = `
  rentabee, rentabee.ch, sowie das rentabee.ch-Logo mit Slogan sind registrierte Marken. Sie dürfen nur nach ausdrücklicher, schriftlicher Bewilligung verwendet werden. Die Texte und Bilder auf der Homepage von rentabee.ch unterliegen den gesetzlichen Copyright-Bestimmungen. Sie dürfen nur nach ausdrücklicher, schriftlicher Bewilligung verwendet werden.

  `;

  return (

    <Page
      inputTop = {inputTop}
      inputBot = {inputBot}
      inputFooter = {inputFooter}
    />

    );
};
