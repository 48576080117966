import React from "react";
import { Page } from "../Page/Page";

export default () => {
  const inputTop = `### Bei rentabee.ch als Imker/in mitmachen lohnt sich!
  ---
  `;
  const inputBot = `rentabee.ch öffnet einem breiten Publikum den Zugang zu einem interessanten, vielfältigen und für uns alle lebenswichtigen Tier: zur Biene! Du vermittelst diesen Zugang, indem Du rentabee.ch-Teilnehmer/innen an Deinem Hobby teilhaben lässt. rentabee.ch ist nicht profitorientiert und hat den alleinigen Zweck, einem breiten Publikum den Zugang zu den Bienen zu öffnen. Die Details erfährst Du unter LEISTUNGEN. Die Voraussetzungen, um bei rentabee.ch mitzumachen erfüllst Du, wenn Du Freude hast, auch Bienen-Ungewohnte an Deinem Hobby teilhaben zu lassen und wenn Du Mitglied in einem Imkerverband bist. Wenn Du noch Fragen hast, rufst Du uns am besten an oder schreibst uns eine E-Mail. Wenn alles klar ist: Melde Dich hier an!
  `;
  const newUrl = "/register/";

  return (

    <Page
      inputTop = {inputTop}
      inputBot = {inputBot}
      newUrl = {newUrl}
    />

  );
};
