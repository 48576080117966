import axios from "axios";
import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import validator from "validator";

export default () => {
  const [vorname, setVorname] = useState("");
  const [nachname, setNachname] = useState("");
  const [strasse, setStrasse] = useState("");
  const [plz, setPlz] = useState("");
  const [ort, setOrt] = useState("");
  const [email, setEmail] = useState("");
  const [geburtsdatum, setGeburtsdatum] = useState("");
  const [geschVorname, setGeschVorname] = useState("");
  const [geschNachname, setGeschNachname] = useState("");
  const [geschStrasse, setGeschStrasse] = useState("");
  const [geschPlz, setGeschPlz] = useState("");
  const [geschOrt, setGeschOrt] = useState("");
  const [geschEmail, setGeschEmail] = useState("");
  const [geschGeburtsdatum, setGeschGeburtsdatum] = useState("");
  const [kommentar, setKommentar] = useState("");
  const [acceptedTac, setAcceptedTac] = useState(false);
  const [phone, setPhone] = useState("");
  const [geschPhone, setGeschPhone] = useState("");
  const [isPresent, setIsPresent] = useState(false);
  const [sendPresent, setSendPresent] = useState("No");
  const [didSignUp, setDidSignUp] = useState(false);
  const [errorsAll, setErrorsAll]: any = useState({});
  const [signUpMessage, setSignUpMessage] = useState("");
  const [signUpSuccess, setSignUpSuccess] = useState(false);

  const validateAll = () => {
    const errors: any = {};
    if (validator.isEmpty(vorname)) {
      errors.Vorname = "Bitte geben Sie einen Vornamen an";
    }
    if (validator.isEmpty(nachname)) {
      errors.Name = "Bitte geben Sie einen Nachnamen an";
    }
    if (validator.isEmpty(strasse)) {
      errors.Strasse = "Bitte geben Sie eine Strasse an";
    }
    if (!validator.isNumeric(plz)) {
      errors.PLZ = "Bitte geben Sie eine gültige Postleitzahl an";
    }
    if (!validator.isEmail(email)) {
      errors.Mail = "Bitte geben Sie eine gültige E-Mail Adresse an";
    }
    if (!acceptedTac) {
      errors.Tac =
        "Die Leistungen müssen angenommen werden, um die Anmeldung abzuschliessen";
    }
    if (validator.isEmpty(geschVorname) && isPresent) {
      errors.GeschVorname = "Bitte geben Sie einen Vornamen an";
    }
    if (validator.isEmpty(geschNachname) && isPresent) {
      errors.GeschName = "Bitte geben Sie einen Nachnamen an";
    }
    if (validator.isEmpty(geschStrasse) && isPresent) {
      errors.GeschStrasse = "Bitte geben Sie eine Strasse an";
    }
    if (!validator.isNumeric(geschPlz) && isPresent) {
      errors.GeschPLZ = "Bitte geben Sie eine gültige Postleitzahl an";
    }
    if (!validator.isEmail(geschEmail) && isPresent) {
      errors.GeschMail = "Bitte geben Sie eine gültige E-Mail Adresse an";
    }
    return errors;
  };

  // useEffect(() => {
  //   if(true || !(Object.keys(errorsAll).length === 0) || isFirstRun.current){
  //     isFirstRun.current = false;
  //   }else{

  //   .then(function(response) {

  //   })
  //   .catch(function(error) {

  //     console.log(error);
  //   }).finally(function() {

  //     setDidSignUp(true);

  //   });

  // setDidSignUp(true);
  //   }
  // }, [errorsAll])

  const handleChange = (e: any) => {
    switch (e.target.id) {
      case "Vorname": {
        setVorname(e.target.value);
        break;
      }
      case "Name": {
        setNachname(e.target.value);
        break;
      }
      case "Strasse": {
        setStrasse(e.target.value);
        break;
      }
      case "PLZ": {
        setPlz(e.target.value);
        break;
      }
      case "Ort": {
        setOrt(e.target.value);
        break;
      }
      case "Tel": {
        let formattedText = e.target.value;
        if (
          formattedText.length > 0 &&
          e.nativeEvent.inputType === "insertText"
        ) {
          formattedText = formattedText.match(
            new RegExp(
              "(^0[1-9]{2}$|^0[1-9]{2}\\s{1}\\w{3}$|^0[1-9]{2}\\s{1}\\w{3}\\s{1}\\w{2}$|^(0041$|\\+41$)|^(0041|\\+41)\\s{1}\\w{2}$|^(0041|\\+41)\\s{1}\\w{2}\\s{1}\\w{3}$|^(0041|\\+41)\\s{1}\\w{2}\\s{1}\\w{3}\\s{1}\\w{2}$)",
              "g"
            )
          )
            ? formattedText + " "
            : formattedText;
        }
        if (e.nativeEvent.inputType === "deleteContentBackward") {
          formattedText = formattedText.trim();
        }
        setPhone(formattedText);
        break;
      }
      case "Mail": {
        setEmail(e.target.value);
        break;
      }
      case "Geburtsdatum": {
        setGeburtsdatum(e.target.value);
        break;
      }
      case "Gesch_Vorname": {
        setGeschVorname(e.target.value);
        break;
      }
      case "Gesch_Name": {
        setGeschNachname(e.target.value);
        break;
      }
      case "Gesch_Strasse": {
        setGeschStrasse(e.target.value);
        break;
      }
      case "Gesch_PLZ": {
        setGeschPlz(e.target.value);
        break;
      }
      case "Gesch_Ort": {
        setGeschOrt(e.target.value);
        break;
      }
      case "Gesch_Tel": {
        let formattedText = e.target.value;
        if (
          formattedText.length > 0 &&
          e.nativeEvent.inputType === "insertText"
        ) {
          formattedText = formattedText.match(
            new RegExp(
              "(^0[1-9]{2}$|^0[1-9]{2}\\s{1}\\w{3}$|^0[1-9]{2}\\s{1}\\w{3}\\s{1}\\w{2}$|^(0041$|\\+41$)|^(0041|\\+41)\\s{1}\\w{2}$|^(0041|\\+41)\\s{1}\\w{2}\\s{1}\\w{3}$|^(0041|\\+41)\\s{1}\\w{2}\\s{1}\\w{3}\\s{1}\\w{2}$)",
              "g"
            )
          )
            ? formattedText + " "
            : formattedText;
        }
        if (e.nativeEvent.inputType === "deleteContentBackward") {
          formattedText = formattedText.trim();
        }
        setGeschPhone(formattedText);
        break;
      }
      case "Gesch_Mail": {
        setGeschEmail(e.target.value);
        break;
      }
      case "Gesch_Geburtsdatum": {
        setGeschGeburtsdatum(e.target.value);
        break;
      }
      case "Bemerkungen": {
        setKommentar(e.target.value);
        break;
      }
      case "radio1": {
        setIsPresent(false);
        setSendPresent("No");
        break;
      }
      case "radio2": {
        setIsPresent(true);
        setSendPresent("Yes");
        break;
      }
      case "Agbs": {
        setAcceptedTac(!acceptedTac);
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const phoneS = /^0[1-9]{2}/.test(phone.substring(0, 3))
      ? phone.replace(
          new RegExp("(^0[1-9]{2})(\\w{3})(\\w{2})(\\w{2})"),
          "$1 $2 $3 $4"
        )
      : phone.replace(
          new RegExp("(^0041|^\\+41)(\\w{2})(\\w{3})(\\w{2})(\\w{2})"),
          "$1 $2 $3 $4 $5"
        );
    const phoneG = isPresent
      ? /^0[1-9]{2}/.test(geschPhone.substring(0, 3))
        ? geschPhone.replace(
            new RegExp("(^0[1-9]{2})(\\w{3})(\\w{2})(\\w{2})"),
            "$1 $2 $3 $4"
          )
        : geschPhone.replace(
            new RegExp("(^0041|^\\+41)(\\w{2})(\\w{3})(\\w{2})(\\w{2})"),
            "$1 $2 $3 $4 $5"
          )
      : "";

    const errors = validateAll();
    setErrorsAll(errors);

    if (Object.keys(errors).length === 0) {
      try {
        // Send Form
        const response = await axios.post(
          "https://prod-101.westeurope.logic.azure.com:443/workflows/597f5647edd54baf8bad6c9879530093/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=SXXLu_OlndT64MvhCwsdJxxYDqP1j0HOFC4mc52gETE",
          {
            topic: "Kunde",
            vorname,
            nachname,
            strasse,
            plz,
            ort,
            email,
            tel: phoneS,
            geburtsdatum,
            isPresent: sendPresent,
            geschVorname,
            geschNachname,
            geschStrasse,
            geschPlz,
            geschOrt,
            geschEmail,
            geschTel: phoneG,
            geschGeburtsdatum,
            kommentar,
          }
        );
        if (response.status === 202) {
          setSignUpSuccess(true);
          setSignUpMessage(
            `
  Vielen Dank für Ihre Anmeldung!

          `
          );
        } else {
          setSignUpSuccess(false);
          setSignUpMessage(
            `
  Ups da ist wohl etwas schief gelaufen!
  
  Die Anmeldung konnte aus technischen Gründen nicht entgegengenommen werden. Klicken Sie unterhalb auf Mail um die Einladung als E-Mail zu versenden oder versuchen Sie es später noch einmal.
    
          `
          );
        }
      } catch (err) {
        setSignUpSuccess(false);
        setSignUpMessage(
          `
  Ups da ist wohl etwas schief gelaufen!
  
  Die Anmeldung konnte aus technischen Gründen nicht entgegengenommen werden. Klicken Sie unterhalb auf Mail um die Einladung als E-Mail zu versenden oder versuchen Sie es später noch einmal.
    
          `
        );
      } finally {
        setDidSignUp(true);
      }
    }

    return false;
  };

  const handleCancel = (e: any) => {
    e.preventDefault();
    setVorname("");
    setNachname("");
    setStrasse("");
    setPlz("");
    setOrt("");
    setPhone("");
    setEmail("");
    setGeburtsdatum("");
    setGeschVorname("");
    setGeschNachname("");
    setGeschStrasse("");
    setGeschPlz("");
    setGeschOrt("");
    setGeschPhone("");
    setGeschEmail("");
    setGeschGeburtsdatum("");
    setKommentar("");
    setIsPresent(false);
    setSendPresent("No");
    setAcceptedTac(false);
  };

  if (didSignUp && !signUpSuccess) {
    const mail = `
mailto:info@rentabee.ch?Subject=Anmeldung Rentabee&body=Hallo!%0D%0AGerne melde ich mich für rentabee.ch mit folgenden Angaben an:
%0D%0AVorname: ${vorname}
%0D%0ANachname: ${nachname}
%0D%0AStrasse: ${strasse}
%0D%0APLZ: ${plz}
%0D%0AOrt: ${ort}
%0D%0ATelefon: ${phone}
%0D%0AE-Mail: ${email}
%0D%0AGeburtsdatum: ${geburtsdatum}
%0D%0AGeschenk: ${sendPresent}
`;
    if (isPresent) {
      const presentMail = `
%0D%0ADas Geschenk ist für:
%0D%0AVorname: ${geschVorname}
%0D%0ANachname: ${geschNachname}
%0D%0AStrasse: ${geschStrasse}
%0D%APLZ: ${geschPlz}
%0D%0AOrt: ${geschOrt}
%0D%0ATelefon: ${geschPhone}
%0D%0AE-Mail: ${geschEmail}
%0D%0AGeburtsdatum: ${geschGeburtsdatum}
`;
      return (
        <div>
          <p>{signUpMessage}</p>
          <a href={mail + presentMail}>Als E-Mail versenden</a>
        </div>
      );
    }

    return (
      <div>
        <p>{signUpMessage}</p>
        <a href={mail}>Als E-Mail versenden</a>
      </div>
    );
  }
  if (didSignUp) {
    return <ReactMarkdown source="Vielen Dank für Ihre Anmeldung" />;
  }
  return (
    <form onSubmit={handleSubmit} id="formcustomer-test">
      <table>
        <tbody>
          <tr>
            <td colSpan={2}>&nbsp;</td>
          </tr>
          <tr>
            <td colSpan={3}>
              <strong>Anmeldeformular</strong>
            </td>
          </tr>
          <tr>
            <td colSpan={3}>
              <p>
                <strong>Meine Angaben:</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td>Vorname:</td>
            <td colSpan={2}>
              <input
                name="Vorname"
                type="text"
                id="Vorname"
                placeholder="Vorname"
                value={vorname}
                onChange={handleChange}
                size={40}
              />
            </td>
          </tr>
          <tr>
            <td colSpan={2} className="invalid-form">
              {errorsAll.Vorname || ""}
            </td>
          </tr>
          <tr>
            <td>Name:</td>
            <td colSpan={2}>
              <input
                name="Name"
                type="text"
                id="Name"
                placeholder="Nachname"
                value={nachname}
                onChange={handleChange}
                size={40}
              />
            </td>
          </tr>
          <tr>
            <td colSpan={2} className="invalid-form">
              {errorsAll.Name || ""}
            </td>
          </tr>
          <tr>
            <td>Strasse:</td>
            <td colSpan={2}>
              <input
                name="Strasse"
                type="text"
                id="Strasse"
                placeholder="Strasse"
                value={strasse}
                onChange={handleChange}
                size={40}
              />
            </td>
          </tr>
          <tr>
            <td colSpan={2} className="invalid-form">
              {errorsAll.Strasse || ""}
            </td>
          </tr>
          <tr>
            <td>PLZ:</td>
            <td colSpan={2}>
              <input
                name="PLZ"
                type="text"
                id="PLZ"
                placeholder="PLZ"
                value={plz}
                onChange={handleChange}
                size={40}
              />
            </td>
          </tr>
          <tr>
            <td colSpan={2} className="invalid-form">
              {errorsAll.PLZ || ""}
            </td>
          </tr>
          <tr>
            <td>Ort:</td>
            <td colSpan={2}>
              <input
                name="Ort"
                type="text"
                id="Ort"
                placeholder="Ort"
                value={ort}
                onChange={handleChange}
                size={40}
              />
            </td>
          </tr>
          <tr>
            <td>Telefon privat:</td>
            <td colSpan={2}>
              <input
                name="Tel"
                type="text"
                id="Tel"
                placeholder="079 123 34 56"
                value={phone}
                onChange={handleChange}
                size={40}
              />
            </td>
          </tr>
          <tr>
            <td>E-Mail Adresse:</td>
            <td colSpan={2}>
              <input
                name="Mail"
                type="email"
                id="Mail"
                placeholder="maxmustermann@muster.com"
                value={email}
                onChange={handleChange}
                size={40}
              />
            </td>
          </tr>
          <tr>
            <td colSpan={2} className="invalid-form">
              {errorsAll.Mail || ""}
            </td>
          </tr>
          <tr>
            <td>Geburtsdatum:</td>
            <td colSpan={2}>
              <input
                name="Geburtsdatum"
                type="date"
                id="Geburtsdatum"
                placeholder="dd.mm.yyyy"
                value={geburtsdatum}
                onChange={handleChange}
                size={40}
              />
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td colSpan={2}>&nbsp;</td>
          </tr>
          <tr>
            <td colSpan={3}>
              <strong>Bitte ankreuzen:</strong>
            </td>
          </tr>
          <tr>
            <td colSpan={3}>
              <strong>
                <input
                  type="radio"
                  name="Fuer"
                  id="radio1"
                  onChange={handleChange}
                  checked={!isPresent}
                />
                <label htmlFor="radio1">
                  Ich bestelle rentabee.ch für mich selber!
                </label>
              </strong>
            </td>
          </tr>
          <tr />
          <tr>
            <td colSpan={2}>
              <strong>
                <input
                  type="radio"
                  name="Fuer"
                  id="radio2"
                  checked={isPresent}
                  onChange={handleChange}
                />
                <label htmlFor="radio2">Ich will rentabee.ch schenken!</label>
              </strong>
            </td>
          </tr>
          {isPresent ? (
            <tr>
              <td>&nbsp;</td>
              <td>
                <p>
                  <strong>Das Geschenk ist für:</strong>
                </p>
                <table>
                  <tbody>
                    <tr>
                      <td>Vorname:</td>
                      <td>
                        <input
                          name="Gesch_Vorname"
                          type="text"
                          id="Gesch_Vorname"
                          placeholder="Vorname"
                          value={geschVorname}
                          onChange={handleChange}
                          size={30}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="invalid-form">
                        {errorsAll.GeschVorname || ""}
                      </td>
                    </tr>
                    <tr>
                      <td>Name:</td>
                      <td>
                        <input
                          name="Gesch_Name"
                          type="text"
                          id="Gesch_Name"
                          placeholder="Nachname"
                          value={geschNachname}
                          onChange={handleChange}
                          size={30}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="invalid-form">
                        {errorsAll.GeschName || ""}
                      </td>
                    </tr>
                    <tr>
                      <td>Strasse:</td>
                      <td>
                        <input
                          name="Gesch_Strasse"
                          type="text"
                          id="Gesch_Strasse"
                          placeholder="Strasse"
                          value={geschStrasse}
                          onChange={handleChange}
                          size={30}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="invalid-form">
                        {errorsAll.GeschStrasse || ""}
                      </td>
                    </tr>
                    <tr>
                      <td>PLZ:</td>
                      <td>
                        <input
                          name="Gesch_PLZ"
                          type="text"
                          id="Gesch_PLZ"
                          placeholder="PLZ"
                          value={geschPlz}
                          onChange={handleChange}
                          size={30}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="invalid-form">
                        {errorsAll.GeschPLZ || ""}
                      </td>
                    </tr>
                    <tr>
                      <td>Ort:</td>
                      <td>
                        <input
                          name="Gesch_Ort"
                          type="text"
                          id="Gesch_Ort"
                          placeholder="Ort"
                          value={geschOrt}
                          onChange={handleChange}
                          size={30}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Telefon privat:</td>
                      <td>
                        <input
                          name="Gesch_Tel"
                          type="text"
                          id="Gesch_Tel"
                          placeholder="079 123 34 56"
                          value={geschPhone}
                          onChange={handleChange}
                          size={30}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>E-Mail Adresse:</td>
                      <td>
                        <input
                          name="Gesch_Mail"
                          type="email"
                          id="Gesch_Mail"
                          placeholder="maxmustermann@muster.com"
                          value={geschEmail}
                          onChange={handleChange}
                          size={30}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2} className="invalid-form">
                        {errorsAll.GeschMail || ""}
                      </td>
                    </tr>
                    <tr>
                      <td>Geburtsdatum:</td>
                      <td>
                        <input
                          name="Gesch_Geburtsdatum"
                          type="date"
                          id="Gesch_Geburtsdatum"
                          value={geschGeburtsdatum}
                          onChange={handleChange}
                          size={30}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          ) : null}

          {isPresent ? (
            <>
              <tr>
                <td colSpan={2}>
                  Der/die Beschenkte wird von rentabee.ch nicht kontaktiert.
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  Die Rechnung und Unterlagen gehen alle immer an den
                  Schenker/die Schenkerin.
                </td>
              </tr>
            </>
          ) : null}
          <tr>
            <td>&nbsp;</td>
            <td colSpan={2}>&nbsp;</td>
          </tr>
          <tr>
            <td>
              <strong>Bemerkungen:</strong>
            </td>
            <td colSpan={2}>
              <label>
                <textarea
                  name="Bemerkungen"
                  id="Bemerkungen"
                  value={kommentar}
                  cols={40}
                  rows={3}
                  onChange={handleChange}
                />
              </label>
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td colSpan={2}>&nbsp;</td>
          </tr>
          <tr>
            <td colSpan={2}>
              <input
                name="Agbs"
                type="checkbox"
                id="Agbs"
                checked={acceptedTac}
                onChange={handleChange}
              />
              <label htmlFor="Agbs">
                Ich habe die AGBs gelesen und akzeptiere sie [
                <a href="/pdf/AGBs_2022.pdf" target="_blank">
                  AGBs
                </a>
                ]
              </label>
            </td>
          </tr>
          <tr>
            <td colSpan={2} className="invalid-form">
              {errorsAll.Tac || ""}
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td colSpan={2}>&nbsp;</td>
          </tr>
          <tr>
            <td colSpan={2}>
              <label>
                <input
                  type="submit"
                  name="button"
                  id="button"
                  value="Abschicken"
                  onClick={handleSubmit}
                  readOnly
                />
              </label>
              <label>
                <input
                  type="reset"
                  name="reset"
                  id="reset"
                  value="Löschen"
                  onClick={handleCancel}
                  readOnly
                />
              </label>
            </td>
          </tr>
        </tbody>
      </table>
      <p>&nbsp;</p>
    </form>
  );
};
