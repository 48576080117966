import React from "react";
import { Page } from "../Page/Page";
import Form from "../Register/form";

export default () => {
const inputTop = `
### Bei rentabee.ch als Imker/in mitmachen lohnt sich!
- - -
`;
const comps = [
 <Form />,
];
return (
    <Page 
      inputTop = {inputTop}
      comps={comps} />

  );
};
