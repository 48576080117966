import React from "react";

export function BackgroundImage(props: any) {
  const{url} = props;

  const addDefaultSrc = (ev: any) => {
    ev.target.src = "images/homeBG.jpg";
  };

  return(
    <img src={`${process.env.PUBLIC_URL}${url}`} className="imageBG" onError={addDefaultSrc} alt="backgroundImage"/>
  );

}
export default BackgroundImage;
