import React, { useState } from "react";
import { BrowserRouter as Router, Link, Redirect, Route, Switch } from "react-router-dom";

import { BackgroundImage } from "../BackgroundImage/backgroundImage";
import BienenTv from "../BienenTv/bienentv";
import Home from "../Home/home";
import InteressierteImker from "../InteressierteImker/interessierteimker";
import Kontakt from "../Kontakt/kontakt";
import Leistungen from "../Leistungen/leistungen";
import Medien from "../Medien/medien";
import Register from "../Register/register";
import Registercustomer from "../RegisterCustomer/registercustomer";

export default () => {
  const [name] = useState("rentabee.ch");

  const [pageName, setPageName] = useState(
    window.location.pathname === "/"
      ? "Home"
      : window.location.pathname
          .replace(/\//g, "")
          .charAt(0)
          .toUpperCase() + window.location.pathname.replace(/\//g, "").slice(1),
  );
  document.title = name;

  return (
    <Router>
      <main>
        <div className="completePage">
          <header>
            <div id="arrow-right" />

            <img src={"/images/logo.jpg"} alt="logo" id="logo-img" />

            <div className="menuBox">
              <h1>{pageName}</h1>
              <nav className="menuBoxNav">
                <ul>
                  <li>
                    <Link to="/" onClick={() => setPageName("Home")}>
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/mitmachen/"
                      onClick={() => setPageName("Mitmachen")}
                    >
                      Mitmachen
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/leistungen/"
                      onClick={() => setPageName("Leistungen")}
                    >
                      Leistungen
                    </Link>
                  </li>
                  <li>
                    <Link to="/medien/" onClick={() => setPageName("Medien")}>
                      Medien
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/interessierteimker/"
                      onClick={() => setPageName("Interessierte Imker")}
                    >
                      Interessierte Imker
                    </Link>
                  </li>
                  <li>
                    <Link to="/kontakt/" onClick={() => setPageName("Kontakt")}>
                      Kontakt
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </header>
          <div className="page">
          <Switch>
            <Route path="/" exact component={Home} setPageName />
            <Route path="/leistungen/" component={Leistungen} />
            <Route path="/medien/" component={Medien} />
            <Route path="/interessierteimker/" component={InteressierteImker} />
            <Route path="/kontakt/" component={Kontakt} />
            <Route path="/register/" component={Register} />
            <Route path="/mitmachen/" component={Registercustomer} />
            <Route render={() => <Redirect to={{pathname: "/"}} />} />
          </Switch>
          </div>
          <div className="imgDiv">
            <BackgroundImage
              url={
                "/images/" +
                pageName.replace(new RegExp(/\s+/g), "").toLowerCase() +
                "BG.jpg"
              }
            />
          </div>
        </div>
      </main>
    </Router>
  );
};
