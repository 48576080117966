import React from "react";
import { FacebookProvider, Like } from "react-facebook";
import { Page } from "../Page/Page";

export default () => {
  const inputTop = `
### Bienenpatenschaften  für die Schweiz. Seit 2008.
---
`;
  const inputBot = `
** Mit rentabee.ch gehört Dir für ein Jahr ein Bienenvolk! **
Das Bienenvolk trägt Deinen Namen und steht bei einem erfahrenen Verbands-Imker in Deiner Nähe. Der Imker&nbsp;hegt und pflegt Dein Volk und Du hilfst ihm dabei:
Du darfst ihn einmal besuchen und er zeigt Dir alles, was Du wissen willst. Wenn Du willst, darfst Du bei Deinem Bienenvolk selber Hand anlegen. Und das Wichtigste: Natürlich nimmst Du 3 Kilogramm Honig von Deinem Bienenvolk mit nach Hause, mit eigener Etikette! Dein eigenes Bienenvolk kostet für ein Kalenderjahr 240 Franken. Mehr dazu findest Du unter MITMACHEN.`;
  const comps = [
    <FacebookProvider appId="191949137497086">
      <Like href="https://www.facebook.com/rentabee.ch" share />
    </FacebookProvider>,
  ];
  return (

    <Page
      inputTop = {inputTop}
      inputBot = {inputBot}
      comps = {comps}
    />

  );

};
