import React from "react";
import Page from "../Page/Page";

export default () => {
  const inputTop = `
### Über rentabee.ch wurde schon in den folgenden Medien berichtet:
---
`;
  const inputBot = `
* [rentabee.ch in der Bienenzeitung](/pdf/bienenzeitung2010.pdf)
* [rentabee.ch im Wynentaler Blatt](/pdf/wynentalerblatt2012.pdf)
* [rentabee.ch im 20 Minuten](/pdf/20minuten.pdf)
`;

  return (

    <Page
      inputTop={inputTop}
      inputBot={inputBot}
    />

);
};
