import axios from "axios";
import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import validator from "validator";

export default () => {
  const [vorname, setVorname] = useState("");
  const [nachname, setNachname] = useState("");
  const [strasse, setStrasse] = useState("");
  const [plz, setPlz] = useState("");
  const [ort, setOrt] = useState("");
  const [email, setEmail] = useState("");
  const [geburtsdatum, setGeburtsdatum] = useState("");
  const [kommentar, setKommentar] = useState("");
  const [acceptedTac, setAcceptedTac] = useState(false);
  const [phone, setPhone] = useState("");
  const [beeLocation, setBeeLocation] = useState("");
  const [voelker, setVoelker] = useState("");
  const [member, setMember] = useState("");
  const [didSignUp, setDidSignUp] = useState(false);
  const [signUpMessage, setSignUpMessage] = useState("");
  const [errorsAll, setErrorsAll]: any = useState({});
  const [signUpSuccess, setSignUpSuccess] = useState(false);

  const validateAll = () => {
    const errors: any = {};
    if (validator.isEmpty(vorname)) {errors.Vorname = "Ein Name darf nur Buchstaben enthalten"; }
    if (validator.isEmpty(nachname)) {errors.Name = "Bitte geben Sie einen Nachnamen an"; }
    if (validator.isEmpty(strasse)) {errors.Strasse = "Bitte geben Sie eine Strasse an"; }
    if (!validator.isNumeric(plz)) {errors.PLZ = "Bitte geben Sie eine gültige Postleitzahl an"; }
    if (!validator.isEmail(email)) {errors.Mail = "Bitte geben Sie eine gültige E-Mail Adresse an"; }
    if (!acceptedTac) {errors.Tac = "Die Leistungen müssen angenommen werden, um die Anmeldung abzuschliessen"; }


    return errors;
    };

  const handleChange = (e: any) => {

    switch (e.target.id) {
      case "Vorname": {
        setVorname(e.target.value);
        break;
      }
      case "Name": {
        setNachname(e.target.value);
        break;
      }
      case "Strasse": {
        setStrasse(e.target.value);
        break;
      }
      case "PLZ": {
        setPlz(e.target.value);
        break;
      }
      case "Ort": {
        setOrt(e.target.value);
        break;
      }
      case "Tel": {
        let formattedText = e.target.value;
        if (formattedText.length > 0 && e.nativeEvent.inputType === "insertText") {
          formattedText = formattedText.match(new RegExp('(^0[1-9]{2}$|^0[1-9]{2}\\s{1}\\w{3}$|^0[1-9]{2}\\s{1}\\w{3}\\s{1}\\w{2}$|^(0041$|\\\+41$)|^(0041|\\\+41)\\s{1}\\w{2}$|^(0041|\\\+41)\\s{1}\\w{2}\\s{1}\\w{3}$|^(0041|\\\+41)\\s{1}\\w{2}\\s{1}\\w{3}\\s{1}\\w{2}$)', 'g')) ? formattedText + " " : formattedText;
        }
        if (e.nativeEvent.inputType === "deleteContentBackward"){
          formattedText = formattedText.trim()
        }
        setPhone(formattedText);
        break;
      }
      case "Mail": {
        setEmail(e.target.value);
        break;
      }
      case "Geburtsdatum": {
        setGeburtsdatum(e.target.value);
        break;
      }
      case "Standort": {
        setBeeLocation(e.target.value);
        break;
      }
      case "Voelker": {
        setVoelker(e.target.value);
        break;
      }
      case "Bienenverein": {
        setMember(e.target.value);
        break;
      }
      case "Bemerkungen": {
        setKommentar(e.target.value);
        break;
      }
      case "Agbs": {
        debugger;
        setAcceptedTac(!acceptedTac);
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleSubmit = async (e: any) => {

    e.preventDefault();
    const phoneS = ((/^0[1-9]{2}/).test(phone.substring(0,3))) ? phone.replace(new RegExp('(^0[1-9]{2})(\\\w{3})(\\\w{2})(\\\w{2})'), "$1 $2 $3 $4") : phone.replace(new RegExp('(^0041|^\\\+41)(\\\w{2})(\\\w{3})(\\\w{2})(\\\w{2})'), "$1 $2 $3 $4 $5")

    const errors = validateAll();
    setErrorsAll(errors);

    if ((Object.keys(errors).length === 0)) {
        
      try {

        // Send Form
        const response = await axios
          .post(
            "https://prod-101.westeurope.logic.azure.com:443/workflows/597f5647edd54baf8bad6c9879530093/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=SXXLu_OlndT64MvhCwsdJxxYDqP1j0HOFC4mc52gETE",
            {
              topic: "Imker",
              vorname,
              nachname,
              strasse,
              plz,
              ort,
              email,
              tel: phoneS,
              geburtsdatum,
              voelker,
              bienenverein: member,
              standort: beeLocation,
              kommentar,
            },
          );
        if (response.status === 202) {
          setSignUpSuccess(true);
          setSignUpMessage(
            `
  Vielen Dank für Ihre Anmeldung!

          `,
          );
        } else {
          setSignUpSuccess(false);
          setSignUpMessage(
            `
  Ups da ist wohl etwas schief gelaufen!
  
  Die Anmeldung konnte aus technischen Gründen nicht entgegengenommen werden. Klicken Sie unterhalb auf Mail um die Einladung als E-Mail zu versenden oder versuchen Sie es später noch einmal.
    
          `,
          );
        }

      } catch (err) {
        setSignUpSuccess(false);
        setSignUpMessage(
          `
  Ups da ist wohl etwas schief gelaufen!
  
  Die Anmeldung konnte aus technischen Gründen nicht entgegengenommen werden. Klicken Sie unterhalb auf Mail um die Einladung als E-Mail zu versenden oder versuchen Sie es später noch einmal.
    
          `,
        );
      } finally {
        setDidSignUp(true);
      }

    }

    return false;
  };
  const handleCancel = (e: any) => {
    e.preventDefault()
    setVorname('');
    setNachname('');
    setStrasse('');
    setPlz('');
    setOrt('');
    setPhone('');
    setEmail('');
    setBeeLocation('');
    setVoelker('');
    setMember('');
    setKommentar('');

    setAcceptedTac(false);

    return false
  };

  if (didSignUp && !signUpSuccess) {
    
    const mail = `
mailto:info@rentabee.ch?Subject=Anmeldung Rentabee&body=Hallo!%0D%0AGerne melde ich mich für rentabee.ch mit folgenden Angaben an:
%0D%0AVorname: ${vorname}
%0D%0ANachname: ${nachname}
%0D%0AStrasse: ${strasse}
%0D%0APLZ: ${plz}
%0D%0AOrt: ${ort}
%0D%0ATelefon: ${phone}
%0D%0AE-Mail: ${email}
%0D%0AAngaben zu meinen Bienen:
%0D%0AStandort: ${beeLocation}
%0D%0AAnzahl Völker: ${voelker}
%0D%0ABienenverein: ${member}
`;
    return(
      <div>
      <p>{signUpMessage}</p>
      <a href={mail}>Als E-Mail versenden</a>
    </div>
    );
  }
  if (didSignUp) {
    return <ReactMarkdown source="Vielen Dank für Ihre Anmeldung" />;
  }
  
  return (
    <form onSubmit={handleSubmit} id="test-form">
      <table className="submit-table">
        <tbody>
          <tr>
            <td>&nbsp;</td>
            <td colSpan={2}>&nbsp;</td>
          </tr>
          <tr>
            <td colSpan={3}>
              <strong>Anmeldeformular Interessierte Imker/Innen</strong>
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td colSpan={2}>&nbsp;</td>
          </tr>
          <tr>
            <td colSpan={3}>
              <p>
                <strong>Meine Angaben:</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td>Vorname:</td>

            <td colSpan={2}>
              <input
                name="Vorname"
                type="text"
                id="Vorname"
                value={vorname}
                placeholder="Vorname"
                onChange={handleChange}
                size={40}
              />
            </td>
          </tr>
          <tr>
            <td colSpan={2} className="invalid-form">{errorsAll.Vorname || ""}</td>
          </tr>
          <tr>
            <td>Name:</td>
            <td colSpan={2}>
              <input
                name="Name"
                type="text"
                id="Name"
                value={nachname}
                placeholder="Name"
                onChange={handleChange}
                size={40}
              />
            </td>
          </tr>
          <tr>
            <td colSpan={2} className="invalid-form">{errorsAll.Name}</td>
          </tr>
          <tr>
            <td>Strasse:</td>
            <td colSpan={2}>
              <input
                name="Strasse"
                type="text"
                id="Strasse"
                value={strasse}
                placeholder="Strasse"
                onChange={handleChange}
                size={40}
              />
            </td>
          </tr>
          <tr>
            <td colSpan={2} className="invalid-form">{errorsAll.Strasse}</td>
          </tr>
          <tr>
            <td>PLZ:</td>
            <td colSpan={2}>
              <input
                name="PLZ"
                type="text"
                id="PLZ"
                value={plz}
                placeholder="PLZ"
                onChange={handleChange}
                size={40}
              />
            </td>
          </tr>
          <tr>
            <td colSpan={2} className="invalid-form">{errorsAll.PLZ}</td>
          </tr>
          <tr>
            <td>Ort</td>
            <td colSpan={2}>
              <input
                name="Ort"
                type="text"
                id="Ort"
                value={ort}
                placeholder="Ort"
                onChange={handleChange}
                size={40}
              />
            </td>
          </tr>
          <tr>
            <td>Telefon privat:</td>
            <td colSpan={2}>
              <input
                name="Tel"
                type="text"
                id="Tel"
                value={phone}
                placeholder="079 123 34 56"
                onChange={handleChange}
                size={40}
              />
            </td>
          </tr>
          <tr>
            <td>E-Mail Adresse:</td>
            <td colSpan={2}>
              <input
                name="Mail"
                type="email"
                id="Mail"
                value={email}
                placeholder="maxmustermann@muster.com"
                onChange={handleChange}
                size={40}
              />
            </td>
          </tr>
          <tr>
            <td colSpan={2} className="invalid-form">{errorsAll.Mail}</td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td colSpan={2}>&nbsp;</td>
          </tr>
          <tr>
            <td colSpan={3}>
              <p>
                <strong>Angaben zu meinen Bienen</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td>Standort:</td>
            <td colSpan={2}>
              <input
                name="Standort"
                type="text"
                id="Standort"
                value={beeLocation}
                onChange={handleChange}
                size={40}
              />
            </td>
          </tr>
          <tr>
            <td>Wieviele Völker stellst Du für rentabee.ch zur Verfügung:</td>
            <td colSpan={2}>
              <input
                name="Voelker"
                type="number"
                id="Voelker"
                value={voelker}
                onChange={handleChange}
                size={40}
              />
            </td>
          </tr>
          <tr>
            <td>Mitglied in welchem Bienenverein:</td>
            <td colSpan={2}>
              <input
                name="Bienenverein"
                type="text"
                id="Bienenverein"
                value={member}
                onChange={handleChange}
                size={40}
              />
            </td>
          </tr>

          <tr>
            <td>&nbsp;</td>
            <td colSpan={2}>&nbsp;</td>
          </tr>
          <tr>
            <td>
              <strong>Bemerkungen:</strong>
            </td>
            <td colSpan={2}>
              <label>
                <textarea
                  name="Bemerkungen"
                  id="Bemerkungen"
                  value={kommentar}
                  cols={40}
                  rows={3}
                  onChange={handleChange}
                />
              </label>
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td colSpan={2}>&nbsp;</td>
          </tr>
          <tr>
            <td className="tac-button" colSpan={2}>       
                <input name="Agbs" type="checkbox" id="Agbs" checked={acceptedTac} onChange={handleChange}/>
                <label htmlFor="Agbs">Ich akzeptiere die unter Leistungen aufgeführten Punkte</label>
            </td>
          </tr>
          <tr>
            <td colSpan={2} className="invalid-form">{errorsAll.Tac}</td>
          </tr>

          <tr>
            <td>&nbsp;</td>
            <td colSpan={2}>&nbsp;</td>
          </tr>
          <tr>
            <td colSpan={2}>
              <label>
                <input
                  type="submit"
                  name="button"
                  id="button"
                  value="Abschicken"
                  onClick={handleSubmit}
                  readOnly
                />
              </label>
              <label>
                <input
                  type="reset"
                  name="reset"
                  id="reset"
                  value="Löschen"
                  onClick={handleCancel}
                  readOnly
                />
              </label>
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  );
};
